const majors = [
  'Aerospace Engineering',
  'Anth (Conc Clim Chng&Humn Sol)',
  'Anth (Conc Sociocultural Anth)',
  'Anthropology(Conc in Archaeol)',
  'Anthropology(Conc in Bio Anth)',
  'Biochemistry',
  'Biochemistry and Cell Biology',
  'Bioengineering',
  'Bioengineering (Biotechnology)',
  'Bioengineering: Bioinformatics',
  'Bioengineering: BioSystems',
  'Biological Anthropology',
  'Biology w/Spec Bioinformatics',
  'BS PblHlth w/Con Biostatistics',
  'BS PblHlth w/Con Clmt&EnvrnSci',
  'BS PblHlth w/Con CmntyHlth Sci',
  'BS PblHlth w/Con Epidemiology',
  'BS PblHlth w/Con HlthPol&Mgmt',
  'BS PblHlth w/Con Medicine Sci',
  'Business Psychology',
  'Chemical Engineering',
  'Chemistry',
  'Chinese Studies',
  'Classical Studies',
  'Cogn & Behavioral Neuroscience',
  'Cognitive Science',
  'Cogn Sci/Spec MachLrn & NuerCp',
  'Cogn Sci w/Spec Clin Asp Cogn',
  'Cogn Sci w/Spec Design & Inter',
  'Cogn Sci w/Specializ Neurosci',
  'Cogn Sci w/Spec Lang & Culture',
  'Communication',
  'Comp Sci w/Spec Bioinformatics',
  'Computer Engineering',
  'Computer Science',
  'Critical Gender Studies',
  'Dance',
  'Data Science',
  'Earth Sciences',
  'Ecology, Behavior & Evolution',
  'Economics',
  'Education Sciences',
  'Electrical Engin & Society',
  'Electrical Engineering',
  'Engineering Physics',
  'Environmental Chemistry',
  'Environmental Engineering',
  'Environ Sys (Earth Sciences)',
  'Environ Sys (Ecol,Behav&Evol)',
  'Environ Sys(Environ Chemistry)',
  'Environ Sys (Environ Policy)',
  'Ethnic Studies',
  'General Biology',
  'General Physics',
  'General Physics/Secondary Educ',
  'German Studies',
  'Global Health',
  'Global South Studies',
  'History',
  'Human Biology',
  'Human Developmental Sciences',
  'HumDevSci w/Spec Eqty& Divrsty',
  'HumDevSci w/Spec Healthy Aging',
  'Interdisc Computing & the Arts',
  'International Studies',
  'International Studies-Anthro',
  'International Studies-Econ',
  'International Studies-History',
  'International Studies-Intl Bus',
  'International Studies-Linguist',
  'International Studies-Lit',
  'International Studies-Phil',
  'International Studies-Poli Sci',
  'International Studies-Sociol',
  'Italian Studies',
  'Japanese Studies',
  'Jewish Studies',
  'Joint Major Mathematics & Econ',
  'Language Studies',
  'Latin American Studies',
  'Latin Am Stu (Con in Mexico)',
  'Latin Am Stu (Con Mig&Brdr St)',
  'Linguistics',
  'Linguistics(Spec Cogn & Lang)',
  'Linguistics(Spec Lang&Society)',
  'Linguistics(Spec Spch&LangSci)',
  'Literature/Writing',
  'Literatures in English',
  'Management Science',
  'Marine Biology',
  'Mathematics',
  'Mathematics (Applied)',
  'Mathematics-Computer Science',
  'Mathematics-Scientif Computatn',
  'Mathematics/Applied Science',
  'Mathematics/Secondary Educ',
  'Mechanical Engineering',
  'MechEngW/Spec Cntrl & Robotics',
  'MechEngW/SpecFluidMech&ThrmlSy',
  'MechEngW/Spec Material Sci&Eng',
  'MechEngW/SpecMechanics of Mat',
  'MechEngW/SpecRnEnergy&EnvFlows',
  'Microbiology',
  'Molecular and Cell Biology',
  'Molecular Biology',
  'Molecular Synthesis',
  'Music',
  'Music Humanities',
  'NanoEngineering',
  'Neurobiology',
  'Oceanic & Atmospheric Sciences',
  'Pharmacological Chemistry',
  'Philosophy',
  'Physics',
  'Physics-Biophysics',
  'Physics w/Specializ Astrophys',
  'Physics w/Specializ Earth Scis',
  'Physics w/Specializ Mtrls Phys',
  'Physiology & Neuroscience',
  'Phys w/Spec Computational Phys',
  'Political Science',
  'Political Sci/Amer Politics',
  'Political Sci/Compar Politics',
  'Political Sci/Data Analytics',
  'Political Sci/Intntl Relations',
  'Political Sci/Political Theory',
  'Political Sci/Public Law',
  'Political Sci/Public Policy',
  'Political Sci/Race Eth and Pol',
  'Probability & Statistics',
  'Psychology',
  'Psychology/Clinical Psychology',
  'Psychology/Cognitive Psych',
  'Psychology/Developmental Psych',
  'Psychology/Human Health',
  'Psychology/Sensation&Perceptn',
  'Psychology/Social Psychology',
  'Public Health',
  'Real Estate and Development',
  'Russian EastEuro & Eurasian St',
  'Sociology',
  'Sociology-American Studies',
  'Sociology-Culture/Communic',
  'Sociology-Economy and Society',
  'Sociology-International Stu',
  'Sociology-Law and Society',
  'Sociology-Science and Medicine',
  'Sociology-Social Inequality',
  'Spanish Literature',
  'Speculative Design',
  'Structural Engineering',
  'Study of Religion',
  'Theatre',
  'Undeclared',
  'Urban Studies and Planning',
  'Visual Arts(Art Hist/Criticsm)',
  'Visual Arts (Media)',
  'Visual Arts (Studio)',
  'World Literature and Culture',
];

export default majors;
